<!-- 走访任务详情 -->
<template>
  <div class="container visitingTasks-warp  flex-column">
    <div class="common-head">
      <img
        :src="require('@/assets/image/personbg.png')"
        alt=""
        class="bg-img"
      />
      <img class="img-title" :src="require('@/assets/image/zfrw.png')" alt="" />
      <div class="head-bottom-warp">
        <div class="head-item align-left blue">
          <div class="bg-blur"></div>
          <img
            :src="require('@/assets/image/sjicon.png')"
            class="img-icon"
          /><span>走访任务详情</span>
        </div>
      </div>
    </div>
    <div class="panel-warp flexone">
      <ul class="panel-warp-ul">
        <li>
          <div class="panel-item-left bra3 panel-item-block">走访名称</div>
          <div class="panel-item-right bra3 panel-item-block plr">{{forms.name}}</div>
        </li>
        <li>
          <div class="panel-item-left bra3 panel-item-block">走访类型</div>
          <div class="panel-item-right bra3 panel-item-block plr">{{ $dt.changeCodeName('interview_type',  forms.type )}}</div>
        </li>
        <li>
          <div class="panel-item-left bra3 panel-item-block">一级网格</div>
          <div class="panel-item-right bra3 panel-item-block plr">
            {{forms.areaOne}}
          </div>
        </li>
        <li>
          <div class="panel-item-left bra3 panel-item-block">二级网格</div>
          <div class="panel-item-right bra3 panel-item-block plr">
            {{forms.areaTwo}}
          </div>
        </li>
        <li>
          <div class="panel-item-left bra3 panel-item-block">三级网格</div>
          <div class="panel-item-right bra3 plr panel-item-block">
             {{forms.areaThree}}
          </div>
        </li>
        <li>
          <div class="panel-item-left bra3 panel-item-block">走访选择</div>
          <div class="panel-item-right bra3">
            <div class="panel-item-block plr">{{ $dt.changeCodeName('interview_target',  forms.target )}}</div>
            <div class="panel-item-block plr bt2">{{forms.target_info}}</div>
          </div>
        </li>
        <li>
          <div class="panel-item-left bra3 panel-item-block align-flex-top">
            详细地址
          </div>
          <div class="panel-item-right bra3 plr panel-item-block">{{forms.addr}}</div>
        </li>
        <!-- <li>
          <div class="panel-item-left bra3 panel-item-block align-flex-top">
            月走访次数
          </div>
          <div class="panel-item-right bra3 panel-item-block fill">
            <div class="d-f-s">
              <div class="panel-item-block plr br2 flex1">10次</div>
              <div class="panel-item-block plr br2 flex1">年走访次数</div>
              <div class="panel-item-block plr flex1">10次</div>
            </div>
          </div>
        </li> -->
        <li>
          <div class="panel-item-left bra3 panel-item-block align-flex-top">
            走访说明
          </div>
          <div class="panel-item-right bra3 plr panel-item-block">{{forms.remark}}</div>
        </li>
        <li>
            <div class="panel-item-left bra3 panel-item-block">照片</div>
            <div
              class="panel-item-right bra3 panel-item-block fill panel-img-warp"
            >
              <img
                v-for="(item,index) in forms.photos"
                :key="index"
                :src="item"
                class="panel-img-square"
                alt=""
                @click="handlePreImg(forms.photos,index)"
              />
            </div>
          </li>
          <li>
            <div class="panel-item-left bra3 panel-item-block">视频</div>
            <div
              class="panel-item-right bra3 panel-item-block fill panel-img-warp"
            >
            <!-- <video
                v-for="(item,index) in forms.videos"
                :key="index"
                :src="item"
                class="panel-img-rec"
                @click="handlePreVideo(item)"
              ></video> -->
              <div class="panel-img-rec video-bg-warp" v-for="(item, index) in forms.videos"      @click="handlePreVideo(item)" :key="index">
                <img  src="../../assets/image/shipin-copy.png" />
              </div>
            </div>
          </li>
      </ul>
       <van-image-preview v-model="show" closeable :images="preImages" :start-position="startIndex"> 
      </van-image-preview>
      <van-dialog
        style="width:100%;border-radius:0;height:200px"
        theme="default"
        v-model="showvideoplay"
        :show-cancel-button="false"
        :show-confirm-button="false"
        closeOnClickOverlay
      >
        <video
          controls
          preload="auto"
          style="width:100%;height:200px;object-fit: contain;"
          :src="videourl"
          playsinline 
          x5-video-player-type="h5" 
          x5-video-player-fullscreen="true"
          x5-video-orientation="portraint" 
          x-webkit-airplay="true"
          v-if="videourl"
        ></video>
      </van-dialog>
    </div>
  </div>
</template>

<script>
import mixin from "@/mixin/detailMixin";
export default {
  mixins: [mixin],
  data() {
    return {
         interfaceUrl: "/api/interview/get",
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="less">
.visitingTasks-warp {
  letter-spacing: 0px;
  position: relative;
  overflow: auto;
}
</style>
